import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom"; // Use useHistory and Link for navigation
import "./Journal.css"; // Import the CSS file for styling
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import JournalEntries from "./JournalEntries"; // Import the JournalEntries component
import api from "../services/api";

const JournalList = () => {
  const [entries, setEntries] = useState([]);
  const [category, setCategory] = useState("");
  const [sortOrder, setSortOrder] = useState("mostRecent"); // New state for sorting
  const history = useHistory(); // Initialize useHistory for navigation
  const { t } = useTranslation();

  useEffect(() => {
    fetchEntries();
  }, [category, sortOrder]); // Trigger fetch on category or sortOrder change

  const fetchEntries = async () => {
    try {
      const response = await api.get(`/journal`, {
        params: {
          category: category || undefined, // Only include category if it's set
          sortOrder: sortOrder || "mostRecent", // Include sortOrder
        }
      });
      setEntries(response.data);
    } catch (error) {
      console.error("Error fetching journal entries", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/journal/${id}`);
      fetchEntries(); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting journal entry", error);
    }
  };

  return (
    <div className="journal-container">
      <div className="journal-header">
        <h2>{t("journal_header")}</h2>
      </div>
      <p>{t("journal_message")}</p>

      {/* Add a button to create a new journal entry */}
      <Link to="/journal/create" className="btn-create-entry">
        {t("new_entry")}
      </Link>

      {/* Add filters for category and sort order */}
      <div className="filters">
        {/* Category Filter */}
        <div className="filter-group category-filter">
          <label htmlFor="category" className="category-label">
            {t("filter_category")}
          </label>
          <select
            id="category"
            className="category-dropdown"
            onChange={(e) => setCategory(e.target.value)}
            value={category}
          >
            <option value="">{t("filter_all")}</option>
            <option value="IRL">{t("filter_IRL")}</option>
            <option value="dreams">{t("filter_dreams")}</option>
            <option value="trance">{t("filter_trances")}</option>
          </select>
        </div>

        {/* Sort Filter */}
        <div className="filter-group sort-filter">
          <label htmlFor="sortOrder" className="sort-label">
            {t("sort_by")}
          </label>
          <select
            id="sortOrder"
            className="sort-dropdown"
            onChange={(e) => setSortOrder(e.target.value)}
            value={sortOrder}
          >
            <option value="mostRecent">{t("sort_most_recent")}</option>
            <option value="oldest">{t("sort_oldest")}</option>
          </select>
        </div>
      </div>

      {/* Render the journal entries */}
      <JournalEntries
        entries={entries}
        handleDelete={handleDelete}
        history={history}
      />
    </div>
  );
};

export default JournalList;
