import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { login } from "../services/api"; // Import the login API function
import { Link } from "react-router-dom";
import "./Login.css";
import "./Message.css";

const Login = ({ onLogin }) => {
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [visibleError, setVisibleError] = useState(""); // New state for fading effect
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  const history = useHistory(); // To redirect the user after login

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await login(identifier, password); // Call the login API

      //console.log("Login response:", response); // Debugging logs
      //console.log("Full login response:", response); // Log the full response object
      //console.log("Response data:", response.message); // Log the data part of the response

      if (response && response.message === "Login successful") {
        localStorage.setItem("isLoggedIn", "true"); // Set the login flag
        onLogin(); // Trigger login state
        setLoading(false);
        history.push("/profile"); // Redirect to profile page
      } else {
        setError(t("invalid_credentials")); // Set the error message content
        setVisibleError(t("invalid_credentials")); // Make the error message visible
        setLoading(false);
        return;
      }
    } catch (err) {
      console.error(
        "Login error:",
        err.response ? err.response.data : err.message
      );

      if (err.response && err.response.status === 401) {
        setError(t("invalid_credentials"));
        setVisibleError(t("invalid_credentials"));
      } else {
        setError(t("login_error"));
        setVisibleError(t("login_error"));
      }

      setLoading(false);
    }
  };

  // Fading effect for the error message
  useEffect(() => {
    if (visibleError) {
      const timer = setTimeout(() => {
        setVisibleError(""); // Hide the error message after 3 seconds
      }, 3000);
      return () => clearTimeout(timer); // Cleanup timer on unmount
    }
  }, [visibleError]);

  return (
    <div className="login-container">
      <h2>{t("login_headline")}</h2>
      <form onSubmit={handleSubmit} className="login-form">
        <div className="form-group">
          <label>{t("username_email")}</label>
          <input
            type="text"
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>{t("password")}</label>
          <div className="password-container">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button
              type="button"
              className="toggle-password-btn"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? t("hide") : t("show")}
            </button>
          </div>
        </div>
        {visibleError && (
          <p className={`message error ${!visibleError ? "fade-out" : ""}`}>
            {error}
          </p>
        )}
        <Link to="/forgot_password" className="forgotpw-link">
          {t("forgot_password")}
        </Link>
        <button type="submit" disabled={loading}>
          {loading ? t("logging") : t("login")}
        </button>
      </form>
    </div>
  );
};

export default Login;
