import React, { useState, useEffect } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import api from "../services/api";
import "./Course.css";

const CourseDetail = () => {
  const { courseId } = useParams(); // Get course ID from route parameters
  const [course, setCourse] = useState(null);
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const history = useHistory();

  useEffect(() => {
    const fetchCourseDetail = async () => {
      try {
        setError("");
        const response = await api.get(`/courses/${courseId}`);
        setCourse(response.data);
      } catch (error) {
        console.error("Error fetching course details:", error);
        setError(t("fetch_error"));
      }
    };

    fetchCourseDetail();
  }, [courseId, t]);

  const handleDelete = async () => {
    if (window.confirm(t("delete_confirmation"))) {
      try {
        await api.delete(`/courses/${courseId}`);
        alert(t("course_deleted_success"));
        history.push("/courses"); // Redirect to courses list
      } catch (error) {
        console.error("Error deleting course:", error);
        alert(t("course_delete_error"));
      }
    }
  };

  if (error) return <p className="error-message">{error}</p>;
  if (!course)
    return <div className="message info">{t("loading_course_details")}</div>;

  return (
    <div className="course-detail-container">
      <h2>{course.title}</h2>

      {/* Assuming `description` contains the HTML string from the database */}
      <div dangerouslySetInnerHTML={{ __html: course.description }} />

      <div className="course-actions">
        <Link to={`/courses/edit/${courseId}`} className="btn-edit-course">
          {t("edit_course_button")}
        </Link>
        <button onClick={handleDelete} className="btn-delete-course">
          {t("delete_course_button")}
        </button>
        <Link
          to={`/courses/${courseId}/lessons/create`}
          className="btn-add-lesson"
        >
          {t("add_lesson_button")}
        </Link>
      </div>

      <h3>{t("lessons_list")}</h3>
      <ul>
        {course.lessons.map((lesson) => (
          <li key={lesson.id}>
            <h4>
              <Link to={`/courses/${courseId}/lessons/${lesson.id}`}>
                {lesson.title}
              </Link>
            </h4>
            <p>
              {lesson.completed
                ? t("lesson_completed")
                : t("lesson_not_completed")}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CourseDetail;
