// Code for the frontpage component
import React from "react";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook
import "./Pages.css"; // Import the CSS file for styling

const PageHome = () => {
  // Initialize the translation function
  const { t } = useTranslation();

  return (
    // <div className="home-container">
    <div className="default-container">
      <h1>{t("greeting_header")}</h1>
      <p>{t("greeting_message")}</p>
    </div>
    // </div>
  );
};

export default PageHome;
