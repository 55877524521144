import ReactDOM from "react-dom";
import LogoutWarning from "../components/LogoutWarning";
import { logOuthandler } from "./authUtils";

// Timer variables
let logoutTimer = null;
let warningTimer = null;
let visibilityChangeListenerAdded = false; // Flag to ensure the listener is added only once

// Function to check if user is logged in
const isLoggedIn = () => {
  return localStorage.getItem("isLoggedIn") === "true";
};

// Function to handle logout
const handleLogout = async () => {
  await logOuthandler();
  window.location.href = '/login';
};

// Function to show warning before logout
const showLogoutWarning = () => {
  const warningContainer = document.createElement("div");
  warningContainer.id = "logout-warning-container";
  document.body.appendChild(warningContainer);

  const handleClose = () => {
    document.body.removeChild(warningContainer);
    resetLogoutTimer();
  };

  ReactDOM.render(<LogoutWarning onClose={handleClose} />, warningContainer);
};

// Function to reset the logout timer
const resetLogoutTimer = () => {
  if (!isLoggedIn()) return;

  clearTimeout(logoutTimer);
  clearTimeout(warningTimer);

  warningTimer = setTimeout(() => {
    showLogoutWarning();
  }, 2700000); // 45 min

  logoutTimer = setTimeout(() => {
    handleLogout();
  }, 3700000); // 61.67 min
};

// Function to add visibility change listener
const addVisibilityChangeListener = () => {
  if (!visibilityChangeListenerAdded) {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    visibilityChangeListenerAdded = true;
  }
};

// Function to remove visibility change listener
const removeVisibilityChangeListener = () => {
  if (visibilityChangeListenerAdded) {
    document.removeEventListener("visibilitychange", handleVisibilityChange);
    visibilityChangeListenerAdded = false;
  }
};

// Visibility change event handler
const handleVisibilityChange = () => {
  if (document.visibilityState === "hidden") {
    localStorage.setItem("superApp_sTime", Date.now().toString());
  } else if (document.visibilityState === "visible") {
    const sessionTime = parseInt(localStorage.getItem("superApp_sTime"), 10);
    const currentTime = Date.now();
    const timeDifference = currentTime - sessionTime;
    console.log("Time difference: ", timeDifference);

    if (timeDifference > 3700000) { // 1 hour in milliseconds
      handleLogout();
    } else {
      resetLogoutTimer();
    }
  }
};

// Set up event listeners for user activity
const events = ["mousemove", "keydown", "click", "touchstart", "touchmove", "touchend"];
const addEventListeners = () => {
  events.forEach(event => window.addEventListener(event, resetLogoutTimer));
};
const removeEventListeners = () => {
  events.forEach(event => window.removeEventListener(event, resetLogoutTimer));
};

export { addEventListeners, removeEventListeners, resetLogoutTimer, handleLogout, addVisibilityChangeListener, removeVisibilityChangeListener, handleVisibilityChange };