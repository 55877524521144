import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./Settings.css";
import api from "../services/api";
import { logOuthandler } from "../services/authUtils";

const Settings = () => {
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");

  const handleDeleteAccount = async () => {
    try {
      const confirmDelete = window.confirm(t("confirm_delete_account"));
      if (!confirmDelete) {
        return;
      }

      const response = await api.delete('/delete-account');

      if (response.data.error) {
        setErrorMessage(response.data.error);
      } else {
        setMessage(
          `${response.data.message}. ${t(
            "account_deleted_redirecting_to_home_shortly"
          )}`
        );
        await logOuthandler();
        setTimeout(() => {
          window.location.href = "/";
        }, 5000); 
      }
    } catch (error) {
      console.error("Error deleting account:", error);
      setErrorMessage(t("delete_account_error"));
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setMessage("");

    if (newPassword !== confirmPassword) {
      setErrorMessage(t("passwords_do_not_match"));
      return;
    }

    try {
      const response = await api.post(
        "/change-password",
        {
          oldPassword,
          newPassword,
        }
      );

      setMessage(response.data.message || t("password_change_success"));
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      console.error("Error changing password:", error);
      setErrorMessage(
        error.response?.data?.error || t("change_password_error")
      );
    }
  };

  return (
    <div className="settings-container">
      <h2>{t("settings")}</h2>
      <h3>{t("change_password")}</h3>
      <form onSubmit={handleChangePassword}>
        <div className="settings-form-group">
          <label>{t("old_password")}:</label>
          <input
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
          />
        </div>
        <div className="settings-form-group">
          <label>{t("new_password")}:</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="settings-form-group">
          <label>{t("confirm_new_password")}:</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button className="settings-button" type="submit">
          {t("change_password_button")}
        </button>
        {message && <p className="settings-message">{message}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </form>
      <br />
      <h3>{t("delete_account")}</h3>
      <button className="settings-button" onClick={handleDeleteAccount}>
        {t("delete_account_button")}
      </button>
    </div>
  );
};

export default Settings;
