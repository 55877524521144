// Code for the frontpage component
import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { useTranslation } from "react-i18next"; // Import the useTranslation hook
import "./Pages.css"; // Import the CSS file for styling

const PageFeatures = () => {
  // Initialize the translation function
  const { t } = useTranslation();

  return (
    <div className="default-container">
      <h1>{t("features_header")}</h1>
      <p>{t("features_message")}</p>
      <div className="default-container-buttons">
        {/* <Link to="/features/reverse" className="default-container button">
          {t("menu_reverse")}
        </Link> */}
        <Link to="/features/values" className="default-container button">
          {t("menu_values")}
        </Link>
        <Link to="/features/imbalance" className="default-container button">
          {t("menu_imbalance")}
        </Link>
        <Link to="/features/moodEnergy" className="default-container button">
          {t("menu_moodEnergy")}
        </Link>
      </div>
    </div>
  );
};

export default PageFeatures;
