import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode"; // Corrected import
import { useTranslation } from "react-i18next";
import "./ValueMapping.css"; // Assuming you're using a separate CSS file
import api from "../services/api";

function ValueMapping() {
  const { t } = useTranslation();
  const [question, setQuestion] = useState("");
  const [prevQuestion, setPrevQuestion] = useState("");
  const [startingQuestionAdded, setStartingQuestionAdded] = useState(false);
  const [answer, setAnswer] = useState("");
  const [userValues, setUserValues] = useState({});
  const [responses, setResponses] = useState([]); // To track user responses
  const [conversationHistory, setConversationHistory] = useState([]); // To track conversation history
  const [repeatMessage, setRepeatMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [reviewMessage, setReviewMessage] = useState("");
  const [saveMessage, setSaveMessage] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [questionSectionVisible, setQuestionSectionVisible] = useState(true);
  const [answerSectionVisible, setAnswerSectionVisible] = useState(false);
  const [reviewSectionVisible, setReviewSectionVisible] = useState(false);
  const [comparisonSectionVisible, setComparisonSectionVisible] =
    useState(false);
  const [resultsSectionVisible, setResultsSectionVisible] = useState(false);
  const [comparisonValues, setComparisonValues] = useState([]);
  const [comparisonIndex, setComparisonIndex] = useState(0);
  const [sortedValues, setSortedValues] = useState([]);
  const [startTime, setStartTime] = useState(Date.now()); // Track response time
  const [repeatTwice, setRepeatTwice] = useState(false);
  const [moveForward, setMoveForward] = useState(false);
  const [addValueManuallyVisible, setAddValueManuallyVisible] = useState(false);
  const [addValueManually, setAddValueManually] = useState(false);
  const [isMapping, setIsMapping] = useState(false);

  const reset = () => {
    window.location.reload();
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isMapping) {
        event.preventDefault();
        event.returnValue = ""; // Varoitusviesti
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isMapping]);

  // Start the value mapping exercise
  const startMapping = async (e) => {
    e.preventDefault(); // Prevent default form submission
    try {
      setErrorMessage(""); // Clear previous errors
      setIsMapping(true);

      const response = await api.post(
        "/start",
        {}, // Empty body
      );
      setPrevQuestion(response.data.question);
      setQuestion(response.data.question);
      setQuestionSectionVisible(false);
      setAnswerSectionVisible(true);
      setStartTime(Date.now()); // Reset start time for new session
    } catch (error) {
      console.error("Error starting value mapping:", error);
      setErrorMessage(t("start_failed"));
    }
  };

  // Function to check repeated answers
  const checkForRepeatedValues = (responses) => {
    const answerCounts = {};
    

    responses.forEach((response) => {
      const answer = response.answer.trim().toLowerCase(); // Normalize the answer
      if (answerCounts[answer]) {
        answerCounts[answer]++;
      } else {
        answerCounts[answer] = 1;
      }
    });

    const counts = Object.values(answerCounts);
    const maxCount = Math.max(...counts);

    if (maxCount >= 3) {
      return "three_times";
    } else if (counts.filter((count) => count >= 2).length >= 2) {
      return "double_pair";
    } else if (maxCount === 2) {
      return "two_times";
    }

    return "none"; // No repeated answers
  };

  // Start adding values manually
  const startAddValue = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    try {
      setErrorMessage(""); // Clear previous error messages

      setIsMapping(true);
      setQuestionSectionVisible(false);
      setAddValueManuallyVisible(true);
      setAddValueManually(true);
      setStartTime(Date.now());
    } catch (error) {
      console.error("Error starting valua-adding:", error);
      setErrorMessage(t("start_failed"));
    }
  };

  // Handle submitting the answer
  const submitAnswer = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    try {
      setErrorMessage(""); // Clear previous error messages

      const responseTime = Math.round((Date.now() - startTime) / 1000); // Calculate response time

      const answerData = {
        answer: answer.trim().toLocaleLowerCase(), // User's current answer
        responses: responses, // Previous responses
        conversation_history: conversationHistory, // Chat history
        response_time: responseTime, // Time taken for the answer
      };

      const response = await api.post(
        "/answer",
        answerData,
      );

    

      // Adding values manually
      if (addValueManually) {
        if (response.data.error) {
          setErrorMessage(response.data.error);
        } else {
          updateUserValues(answer, responseTime);
          setErrorMessage("");
        }
        // Mapping exercise
      } else {
        if (response.data.error) {
          setErrorMessage(response.data.error);
        } else {
          updateUserValues(answer, responseTime);
          setQuestion(response.data.question); // Update question
          setErrorMessage("");
          setRepeatMessage("");
        }

        // Update responses and conversation history
        setResponses([...responses, { answer, responseTime }]);

        if (!startingQuestionAdded) {
          setConversationHistory([
            { role: t("role1"), content: prevQuestion }, // Add the starting question as the first entry
            { role: t("role2"), content: answer },
            ...conversationHistory,
          ]);
          setStartingQuestionAdded(true);
        } else {
          setConversationHistory([
            { role: t("role1"), content: response.data.question },
            { role: t("role2"), content: answer },
            ...conversationHistory,
          ]);
        }

        // Calculate total response time
        const totalResponseTime = responses.reduce(
          (total, response) => total + response.responseTime,
          responseTime
        );
        

        const repeatStatus = checkForRepeatedValues([...responses, { answer }]);
        if (repeatStatus === "three_times" || repeatStatus === "double_pair") {
          // If the answer has been given three times, set the review message
          setReviewMessage(t("answer_circle")); // Potentiaalinen ongelmankohta, näkyykö vastausten määrä oikein???
          setAnswerSectionVisible(false);
          setReviewSectionVisible(true);
          return; // Stop submission
        } else if (repeatStatus === "two_times" && !repeatTwice) {
          // If one answer has been given twice, set the repeat message
          setRepeatMessage(t("repeated_answer"));
          setRepeatTwice(true);
        } else if (responses.length + 1 >= 10 && totalResponseTime > 1800) {
          setRepeatMessage(t("answer_time"));
          setMoveForward(true);
        }
      }

      setAnswer(""); // Clear input field
      setStartTime(Date.now()); // Reset start time for next question
    } catch (error) {
      console.error("Error submitting answer:", error);
      if (error.response && error.response.status === 401) {
        setErrorMessage(t("unauthorized"));
      } else {
        setErrorMessage(t("answer_failed"));
      }
    }
  };

  // Update user values with new answers
  const updateUserValues = (answer, time) => {
    const normalizedAnswer = answer.trim().toLowerCase();
    setUserValues((prevValues) => {
      const newValues = { ...prevValues };
      if (newValues[normalizedAnswer]) {
        newValues[normalizedAnswer].count += 1;
        newValues[normalizedAnswer].time += time;
      } else {
        newValues[normalizedAnswer] = { count: 1, time, score: 0 };
      }
     
      return newValues;
    });
  };

  // Start the review process
  const submitNext = () => {
    setAddValueManuallyVisible(false);
    setAnswerSectionVisible(false);
    setReviewSectionVisible(true);
  };
  // Start the compare process
  const submitCompare = () => {
    setAnswerSectionVisible(false);
    setReviewSectionVisible(false);
    generateComparisonPairs();
  };

  // Generate pairs for comparison
  const generateComparisonPairs = () => {
    const values = Object.keys(userValues);

    if (values.length < 2) {
      setErrorMessage(t("not_enough_values"));
      if (values.length > 0) {
        showResults();
      } else {
        reset();
      }
      return;
    }

    let pairs = [];
    for (let i = 0; i < values.length; i++) {
      for (let j = i + 1; j < values.length; j++) {
        pairs.push([values[i], values[j]]);
      }
    }

    setComparisonValues(pairs);
  };

  // React to changes in comparisonValues to start comparison
  useEffect(() => {
    if (comparisonValues.length > 0) {
      startComparison();
    }
  }, [comparisonValues]);

  // Handle value comparison
  const startComparison = () => {
    if (comparisonIndex < comparisonValues.length) {
      const [optionA, optionB] = comparisonValues[comparisonIndex];
      setComparisonSectionVisible(true);
    } else {
      showResults();
    }
  };

  // Show the results after comparison
  const showResults = () => {
    setComparisonSectionVisible(false);
    const sorted = Object.keys(userValues).sort(
      (a, b) => userValues[b].score - userValues[a].score
    );
    setSortedValues(sorted);
    setResultsSectionVisible(true);
  };

  // Handle value selection during comparison
  const chooseA = () => {
    if (comparisonIndex < comparisonValues.length) {
      const optionA = comparisonValues[comparisonIndex][0];
      setUserValues((prevValues) => ({
        ...prevValues,
        [optionA]: {
          ...prevValues[optionA],
          score: prevValues[optionA].score + 1,
        },
      }));
      setComparisonIndex((prevIndex) => prevIndex + 1);
    }
  };

  // Use useEffect to react to comparisonIndex changes
  useEffect(() => {
    if (comparisonIndex < comparisonValues.length) {
      startComparison();
    } else if (comparisonValues.length > 0) {
      showResults();
    }
  }, [comparisonIndex]);

  const chooseB = () => {
    if (comparisonIndex < comparisonValues.length) {
      const optionB = comparisonValues[comparisonIndex][1];
      setUserValues((prevValues) => ({
        ...prevValues,
        [optionB]: {
          ...prevValues[optionB],
          score: prevValues[optionB].score + 1,
        },
      }));
      setComparisonIndex((prevIndex) => prevIndex + 1);
    }
  };

  // Start a new round
  const startNewRound = async (e) => {
    e.preventDefault();
    try {
      setErrorMessage("");

      if (!isSaved) {
        const confirmNewRound = window.confirm(t("unsaved_changes_warning"));
        if (!confirmNewRound) {
          return;
        }
      }

      const response = await api.post(
        "/new-round",
        { prev_question: prevQuestion }, // Send the previous start question to the API
      );

      if (response.data.error) {
        setErrorMessage(response.data.error);
      } else {
        setQuestion(response.data.question);
        setResponses([]);
        setConversationHistory([]); 
        setAnswerSectionVisible(true); 
        setReviewSectionVisible(false); 
        setComparisonSectionVisible(true);
        setAnswer(""); 
        setSortedValues([]); 
        setComparisonValues([]); 
        setComparisonIndex(0); 
        setResultsSectionVisible(false); 
        setUserValues({});
        setIsSaved(false); 
        setSaveMessage(""); 
        setRepeatMessage("");
        setReviewMessage(""); 
        setRepeatTwice(false); 
        setMoveForward(false); 
        setIsMapping(true);
      }
    } catch (error) {
      console.error("Error starting new round:", error);
      if (error.response && error.response.status === 401) {
        setErrorMessage(t("unauthorized"));
      } else {
        setErrorMessage(t("new_round_failed"));
      }
    }
  };

  // Save values
  const saveValues = async (e) => {
    e.preventDefault();
    try {
      setErrorMessage("");
     
      const response = await api.post(
        "/save-values",
        { values: sortedValues }, // Data to save
      );

      if (response.data.error) {
        setErrorMessage(response.data.error);
      } else if (response.data.message) {
        setSaveMessage(response.data.message);
        setIsSaved(true);
        setIsMapping(false);
      } else {
        //reset();
      }
    } catch (error) {
      console.error("Error saving values:", error);
      if (error.response && error.response.status === 401) {
        setErrorMessage(t("unauthorized"));
      } else {
        setErrorMessage(t("save_failed"));
      }
    }
  };

  const removeValue = (value) => {
    setUserValues((prevValues) => {
      const updatedValues = { ...prevValues };
      delete updatedValues[value]; // Poista arvo listasta
      return updatedValues; // Palauta päivitetty arvojen lista
    });
  };

  // Render the component UI
  return (
    <div className="value-mapping-container">
      <h1>{t("values_header")}</h1>

      {questionSectionVisible && (
        <div>
          <div>
            <h2>{t("values_subheader1")}</h2>
            <p>{t("values_intro")}</p>

            <form onSubmit={startMapping}>
              <button type="submit" className="button">
                {t("start_mapping")}
              </button>
            </form>
          </div>
          <div>
            <h2>{t("values_subheader2")}</h2>
            <p>{t("values_intro2")}</p>
            <button type="button" className="button" onClick={startAddValue}>
              {t("add_values")}
            </button>
          </div>
        </div>
      )}

      {addValueManuallyVisible && (
        <div>
          <h2>{t("values_add_headline")}</h2>
          <p>{t("values_intro2")}</p>
          <form onSubmit={submitAnswer}>
            <label htmlFor="answerInput">{t("add_value_label")}</label>
            <input
              type="text"
              id="answerInput"
              name="answer"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              required
            />
            <button type="submit" className="button">
              {t("add_value_button")}
            </button>
          </form>
          {/* {errorMessage && <p className="error-message">{errorMessage}</p>} */}
          {Object.keys(userValues).length > 0 && (
            <>
              <p>{t("instructions_text")}</p>
              <button type="button" className="button" onClick={submitNext}>
                {t("continue_review")}
              </button>
            </>
          )}
        </div>
      )}

      {answerSectionVisible && (
        <div>
          <h2>{t("question_headline")}</h2>
          <p>{question}</p>
          <form onSubmit={submitAnswer}>
            <label htmlFor="answerInput">{t("answer_label")}</label>
            <input
              type="text"
              id="answerInput"
              name="answer"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              required
            />
            <button type="submit" className="button">
              {t("submit_answer_button")}
            </button>
          </form>
          {/* {errorMessage && <p className="error-message">{errorMessage}</p>} */}
          {repeatMessage && <p className="repeat-message">{repeatMessage}</p>}
          {(repeatTwice || moveForward) && ( // Display the save button only if values are not saved
            <>
              <p>{t("review_answers")}</p>
              <button type="button" className="button" onClick={submitNext}>
                {t("review_answers_button")}
              </button>
            </>
          )}
        </div>
      )}

      {reviewSectionVisible && (
        <div>
          <div className="review-container">
            <h2>{t("review_answers_headline")}</h2>
            {reviewMessage && <p className="review-message">{reviewMessage}</p>}
            <p>{t("delete_values_text")}</p>
            <ul>
              {Object.keys(userValues).map((value) => (
                <li key={value} className="review-value">
                  {value}
                  <span className="review-count-time">
                    ({t("occurance")} {userValues[value].count},{" "}
                    {t("answer_time_counted")} {userValues[value].time}{" "}
                    {t("seconds")})
                  </span>
                  <button
                    type="button"
                    className="button-remove"
                    onClick={() => removeValue(value)}
                  >
                    {t("remove_value")}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <p>{t("continue_comparison_text")}</p>
          <button type="button" className="button" onClick={submitCompare}>
            {t("continue_comparison_button")}
          </button>
        </div>
      )}
      {comparisonSectionVisible &&
        comparisonValues.length > 0 &&
        comparisonIndex < comparisonValues.length && (
          <div>
            <div className="comparison-container">
              <h2>{t("comparison_headline")}</h2>
              {comparisonValues.length > 0 && (
                <div className="comparison-values">
                  <button
                    type="button"
                    className="button-comparison"
                    onClick={chooseA}
                  >
                    {comparisonValues[comparisonIndex][0]}
                  </button>
                  <p className="comparison-text">vs</p>
                  <button
                    type="button"
                    className="button-comparison"
                    onClick={chooseB}
                  >
                    {comparisonValues[comparisonIndex][1]}
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      {resultsSectionVisible && (
        <div>
          <div>
            <h2>{t("final_comparison_headline")}</h2>
            <ol>
              {sortedValues.map((value) => (
                <li key={value} className="result-value">
                  {value}
                  <span className="review-count-time">
                    ({t("points")} {userValues[value].score}, {t("occurance")}{" "}
                    {userValues[value].count}, {t("answer_time_counted")}{" "}
                    {userValues[value].time} {t("seconds")})
                  </span>
                </li>
              ))}
            </ol>
            {!isSaved && ( // Display the save button only if values are not saved
              <button type="button" className="button" onClick={saveValues}>
                {t("save_values")}
              </button>
            )}
            {saveMessage && <p className="save-message">{saveMessage}</p>}
            <p>{t("outro_text")}</p>
          </div>
          {!addValueManually && (
            <button type="button" className="button" onClick={startNewRound}>
              {t("start_new_round")}
            </button>
          )}
          <button type="button" className="button" onClick={reset}>
            {t("end_session")}
          </button>
        </div>
      )}
      {errorMessage && <p className="error-message">{errorMessage}</p>}

      {/* Optionally display the conversation history */}
      {conversationHistory.length > 0 && (
        <div className="conversation-history">
          <h3>{t("conversation_history")}</h3>
          <ul>
            {conversationHistory.map((entry, index) => (
              <li key={index}>
                <strong>{entry.role}:</strong> {entry.content}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default ValueMapping;
