import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import "./ImbalanceMapping.css";
import api from "../services/api";

function ImbalanceMapping() {
  const [questions, setQuestions] = useState({});
  const [answers, setAnswers] = useState({
    MindBody: [],
    MemoryCreativity: [],
    PastFuture: [],
    SuperegoEmotion: [],
  });
  const [submitted, setSubmitted] = useState(false);
  const [results, setResults] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();
  const [currentCategory, setCurrentCategory] = useState("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isSaved, setIsSaved] = useState(false);
  const [saveMessage, setSaveMessage] = useState("");
  const [hasStarted, setHasStarted] = useState(false); // Uusi tila, joka seuraa, onko käyttäjä aloittanut testin


  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const res = await api.get(`/questions`);
        setQuestions(res.data);
        // Alustetaan ensimmäinen kategoria ja kysymysindeksi
        const firstCategory = Object.keys(res.data)[0];
        setCurrentCategory(firstCategory);
        setCurrentQuestionIndex(0);
      } catch (err) {
        console.error(err);
        setErrorMessage(t("fetch_error"));
      }
    };

    if (hasStarted) {
      fetchQuestions();
    }
  }, [api.defaults.baseURL, hasStarted]);

  const reset = () => {
    setErrorMessage(t("reset_message"));
    window.location.reload();
  };

  const handleStart = () => {
    setHasStarted(true);
  };
  // Vastausten käsittely ja seuraavan kysymyksen näyttäminen
  const handleAnswer = (answer) => {
    setAnswers((prev) => ({
      ...prev,
      [currentCategory]: [...prev[currentCategory], answer],
    }));

    const nextQuestionIndex = currentQuestionIndex + 1;

    // Jos nykyisessä kategoriassa on vielä kysymyksiä jäljellä
    if (nextQuestionIndex < questions[currentCategory]?.length) {
      setCurrentQuestionIndex(nextQuestionIndex);
    } else {
      // Siirrytään seuraavaan kategoriaan, jos kysymykset loppuvat
      const categories = Object.keys(questions);
      const nextCategoryIndex = categories.indexOf(currentCategory) + 1;

      if (nextCategoryIndex < categories.length) {
        // Siirrytään seuraavaan kategoriaan
        setCurrentCategory(categories[nextCategoryIndex]);
        setCurrentQuestionIndex(0);
      } else {
        // Kaikki kysymykset on käyty läpi
        handleSubmit(); // Lähetä vastaukset, kun kaikki kysymykset on vastattu
      }
    }
  };

  // Vastausten lähetys autentikoinnin jälkeen
  const handleSubmit = async () => {
    try {
      const res = await api.post(`/submit`, answers);
      setResults(res.data);
      setSubmitted(true);
    } catch (err) {
      console.error(err);
      setErrorMessage(t("submit_error"));
    }
  };

  const saveValues = async (e) => {
    e.preventDefault();
    try {
      setErrorMessage("");
      const response = await api.post(
        `/save-results`,
        { results: results }, // Data to save
      );

      if (response.data.error) {
        setErrorMessage(response.data.error);
      } else if (response.data.message) {
        setSaveMessage(response.data.message);
        setIsSaved(true);
      } else {
        console.log("Something went wrong with saving values.");
      }
    } catch (error) {
      console.error("Error saving values:", error);
      if (error.response && error.response.status === 401) {
        setErrorMessage(t("auth_error"));
      } else {
        setErrorMessage(t("save_error"));
      }
    }
  };

  if (submitted && results) {
    return (
      <div className="app-container">
        <div className="results-container">
          <h1>{t("results_headline")}</h1>
          <p>
            {t("mind_body")} {results.Mind} - {results.Body}
          </p>
          <p>
            {t("memory_creativity")} {results.Memory} - {results.Creativity}
          </p>
          <p>
            {t("past_future")} {results.Past} - {results.Future}
          </p>
          <p>
            {t("morality vs. emotion")} {results.Superego} - {results.Emotion}
          </p>
          {saveMessage && <p className="save-message">{saveMessage}</p>}
          {!isSaved && ( // Display the save button only if values are not saved
            <button type="button" className="button" onClick={saveValues}>
              {t("save_results")}
            </button>
          )}
          <button type="button" className="button" onClick={reset}>
            {t("quit")}
          </button>
        </div>
      </div>
    );
  }

  // Aloitussivu
  if (!hasStarted) {
    return (
      <div className="app-container">
        <h1>{t("imbalance_headline")}</h1>
        <p>{t("imbalance_intro")}</p>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <button onClick={handleStart}>{t("start_exercise")}</button>
      </div>
    );
  }

  if (
    !currentCategory ||
    !questions[currentCategory] ||
    questions[currentCategory].length === 0
  ) {
    return <div>{t("loading")}</div>;
  }

  return (
    <div className="app-container">
      <h1>{t("imbalance_title")}</h1>
      <div className="question-container">
        <p>{questions[currentCategory][currentQuestionIndex]}</p>
        <button onClick={() => handleAnswer("a")}>A</button>
        <button onClick={() => handleAnswer("b")}>B</button>
      </div>
    </div>
  );
}

export default ImbalanceMapping;
