import React from "react";
import { Editor } from "primereact/editor";

// Toolbar configuration for the PrimeReact Editor
const editorModules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }], // Header options
    ["bold", "italic", "underline", "strike"], // Formatting options
    [{ list: "ordered" }, { list: "bullet" }], // List options
    [{ align: [] }], // Text alignment
    ["link"], // Add link button
    ["clean"], // Clear formatting
  ],
};

// PT options for customizing the Editor
const editorPtOptions = {
  toolbar: { style: { display: "none" } }, // Disable default toolbar
};

const CustomEditor = ({ value, onTextChange, style = { height: "320px" } }) => {
  return (
    <Editor
      value={value}
      onTextChange={onTextChange}
      style={style}
      modules={editorModules} // Toolbar configuration
      pt={editorPtOptions} // PT customization
    />
  );
};

export default CustomEditor;
