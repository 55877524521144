import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Course.css";
import { useTranslation } from "react-i18next";
import api from "../services/api";
import RoleBasedComponent from './RoleBasedComponent';

const CoursesList = () => {
  const [courses, setCourses] = useState([]);
  const [error, setError] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      const response = await api.get(`/courses`);
      setCourses(response.data);
    } catch (error) {
      console.error("Error fetching courses:", error);
      setError("Failed to load courses. Please try again.");
    }
  };

  if (error) return <p className="error-message">{error}</p>;
  if (courses.length === 0)
    return <div className="message info">{t("loading_courses")}</div>;

  return (
    <div className="courses-container">
      <div className="courses-header">
        <h2>{t("courses_header")}</h2>
      </div>
      <p>{t("courses_message")}</p>

      {/* Add a button for creating a new course */}
      <RoleBasedComponent requiredRole="admin">
        <div className="create-course-button-container">
          <Link to="/courses/create" className="btn-create-course">
            {t("create_new_course")}
          </Link>
        </div>
      </RoleBasedComponent>

      {/* Display error message if there's an error */}
      {error && <p className="error-message">{error}</p>}

      {/* Display the list of courses */}
      <ul>
        {courses.map((course) => (
          <li key={course.id}>
            <h3>
              <Link to={`/courses/${course.id}`}>{course.title}</Link>
            </h3>
            <div dangerouslySetInnerHTML={{ __html: course.description }} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CoursesList;
