import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import logo from "./assets/images/logo.png";
import PageHome from "./components/PageHome";
import PageFeatures from "./components/PageFeatures";
import Login from "./components/Login";
import Register from "./components/Register";
import Profile from "./components/Profile";
import { logOuthandler } from "./services/authUtils";
import NotFound from "./components/NotFound";
import CreateCourse from "./components/CreateCourse";
import ValueMapping from "./components/ValueMapping";
import ImbalanceMapping from "./components/ImbalanceMapping";
import JournalList from "./components/JournalList";
import JournalForm from "./components/JournalForm";
import JournalEdit from "./components/JournalEdit";
import "./index.css";
import "./i18n";
import { useTranslation } from "react-i18next";
import "flag-icons/css/flag-icons.min.css";
import Settings from "./components/Settings";
import { Redirect } from "react-router-dom";
import MoodEnergyTracking from "./components/MoodEnergyTracking";
import CoursesList from "./components/CoursesList";
import CourseDetail from "./components/CourseDetail";
import Lesson from "./components/Lesson";
import CourseForm from "./components/CourseForm";
import CourseEdit from "./components/CourseEdit";
import LessonForm from "./components/LessonForm";
import LessonEdit from "./components/LessonEdit";
import RoleBasedComponent from "./components/RoleBasedComponent";
import RoleBasedRoute from './components/RoleBasedRoute';
import ResetPassword from "./components/ResetPassword";
import ForgotPassword from "./components/ForgotPassword";
import { resetLogoutTimer, handleVisibilityChange, addVisibilityChangeListener, removeVisibilityChangeListener } from './services/sessionManager';
import CookieConsent from './components/CookieConsent';
import { useCookies } from 'react-cookie';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(true);
  const menuRef = useRef(null); // Reference to the menu element
  const [cookies] = useCookies(["superAppConsentCookie"]);

  const switchLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    setLoggedIn(isLoggedIn);
    setLoading(false);
  }, []);

  // Check session validity
  useEffect(() => {
    // Check session validity immediately
    handleVisibilityChange();
    console.log("App.js: Visibility change listener added");

    resetLogoutTimer();
    addVisibilityChangeListener();

    return () => {
      removeVisibilityChangeListener();
    };
  }, []);
  


  const handleLogout = async () => {
    // Call AuthUtils.js function to handle logout
    await logOuthandler(setLoggedIn, setMenuOpen, setRedirect);
  };


  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setSubmenuOpen(false);
  };

  const toggleSubmenu = () => {
    setSubmenuOpen(!submenuOpen);
  };

  const handleLinkClick = () => {
    setMenuOpen(false);
    setSubmenuOpen(false);
  };

  // Handle clicks outside the menu to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
        setSubmenuOpen(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      {redirect && <Redirect to="/login" />}
      <div>
        <nav ref={menuRef}>
          <div className="logo-container">
            <Link to="/" onClick={handleLinkClick}>
              <img src={logo} alt="Logo" className="logo" />
            </Link>
          </div>

          <div className={`menu ${menuOpen ? "open" : ""}`}>
            <Link to="/" onClick={handleLinkClick}>
              {t("menu_home")}
            </Link>
            {/* Kurssinappi piilotettu */}
            <RoleBasedComponent requiredRole="admin" loggedIn={loggedIn}>
              {loggedIn && (
                <Link to="/courses" onClick={handleLinkClick}>
                  {t("menu_course")}
                </Link>
              )}
            </RoleBasedComponent>

            {loggedIn && (
              <div className="dropdown">
                <button className="dropbtn" onClick={toggleSubmenu}>
                  {t("menu_features")}{" "}
                  {/* <i className="fa fa-caret-down"></i> */}
                </button>
                <div
                  className={`dropdown-content ${submenuOpen ? "show" : ""}`}
                >
                  <Link to="/features" onClick={handleLinkClick}>
                    {t("submenu_features")}
                  </Link>
                  {/* <Link to="/features/reverse" onClick={handleLinkClick}>
                    {t("menu_reverse")}
                  </Link> */}
                  <Link to="/features/values" onClick={handleLinkClick}>
                    {t("menu_values")}
                  </Link>
                  <Link to="/features/imbalance" onClick={handleLinkClick}>
                    {t("menu_imbalance")}
                  </Link>
                  <Link to="/features/moodEnergy" onClick={handleLinkClick}>
                    {t("menu_moodEnergy")}
                  </Link>
                </div>
              </div>
            )}

            {loggedIn && (
              <Link to="/journal" onClick={handleLinkClick}>
                {t("menu_journal")}
              </Link>
            )}

            {!loggedIn && (
              <>
                <Link to="/login" onClick={handleLinkClick}>
                  {t("menu_login")}
                </Link>
                <Link to="/register" onClick={handleLinkClick}>
                  {t("menu_register")}
                </Link>
              </>
            )}

            {loggedIn && (
              <>
                <Link to="/profile" onClick={handleLinkClick}>
                  {t("menu_profile")}
                </Link>
                <Link to="/settings" onClick={handleLinkClick}>
                  {t("menu_settings")}
                </Link>
                <button className="logout-btn" onClick={handleLogout}>
                  {t("menu_logout")}
                </button>
              </>
            )}
          </div>

          <div
            className={`hamburger ${menuOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>

          {menuOpen && (
            <div className="hamburger-menu">
              <div className="language-switcher">
                <span
                  className={`fi fi-gb ${
                    i18n.language === "en" ? "active" : ""
                  }`}
                  onClick={() => switchLanguage("en")}
                  style={{ cursor: "pointer", marginRight: "10px" }}
                ></span>
                <span
                  className={`fi fi-fi ${
                    i18n.language === "fi" ? "active" : ""
                  }`}
                  onClick={() => switchLanguage("fi")}
                  style={{ cursor: "pointer" }}
                ></span>
              </div>
            </div>
          )}
        </nav>

        <Switch>
          <Route exact path="/">
            <PageHome />
          </Route>

          {/* Courses-related routes */}
          
          <RoleBasedRoute
          exact
          path="/courses/:courseId/lessons/create"
          component={LessonForm}
          isAuthenticated={loggedIn}
          requiredRole="admin"
        />
        <RoleBasedRoute
          path="/courses/:courseId/lessons/edit/:lessonId"
          component={LessonEdit}
          isAuthenticated={loggedIn}
          requiredRole="admin"
        />
        <RoleBasedRoute
          exact
          path="/courses/:courseId/lessons/:lessonId"
          component={Lesson}
          isAuthenticated={loggedIn}
          requiredRole="admin"
        />
        <RoleBasedRoute
          exact
          path="/courses/create"
          component={CourseForm}
          isAuthenticated={loggedIn}
          requiredRole="admin"
        />
        <RoleBasedRoute
          path="/courses/edit/:courseId"
          component={CourseEdit}
          isAuthenticated={loggedIn}
          requiredRole="admin"
        />
        <RoleBasedRoute
          exact
          path="/courses/:courseId"
          component={CourseDetail}
          isAuthenticated={loggedIn}
          requiredRole="admin"
        />
        <RoleBasedRoute
          exact
          path="/courses"
          component={CoursesList}
          isAuthenticated={loggedIn}
          requiredRole="admin"
        />

          <PrivateRoute
            path="/features/values"
            component={ValueMapping}
            isAuthenticated={loggedIn}
          />
          <PrivateRoute
            path="/features/imbalance"
            component={ImbalanceMapping}
            isAuthenticated={loggedIn}
          />
          <PrivateRoute
            path="/features/moodEnergy"
            component={MoodEnergyTracking}
            isAuthenticated={loggedIn}
          />
          <PrivateRoute
            path="/features"
            component={PageFeatures}
            isAuthenticated={loggedIn}
          />
          <PrivateRoute
            path="/journal/create"
            component={JournalForm}
            isAuthenticated={loggedIn}
          />
          <PrivateRoute
            path="/journal/edit/:id"
            component={JournalEdit}
            isAuthenticated={loggedIn}
          />
          <PrivateRoute
            path="/journal"
            component={JournalList}
            isAuthenticated={loggedIn}
          />
          <Route path="/login">
            <Login
              onLogin={() => {
                setLoggedIn(true);
              }}
            />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/forgot_password">
            <ForgotPassword />
          </Route>
          <Route path="/reset_password/:token">
            <ResetPassword />
          </Route>
          <PrivateRoute
            path="/profile"
            component={Profile}
            isAuthenticated={loggedIn}
          />
          <PrivateRoute
            path="/settings"
            component={Settings}
            isAuthenticated={loggedIn}
          />
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
        {/* {!cookies.superAppConsentCookie && <CookieConsent />} */}
      </div>
    </Router>
  );
}

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
);

export default App;
