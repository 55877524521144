import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import RoleBasedComponent from './RoleBasedComponent';

const RoleBasedRoute = ({ component: Component, isAuthenticated, requiredRole, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        <RoleBasedComponent requiredRole={requiredRole}>
          <Component {...props} />
        </RoleBasedComponent>
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

export default RoleBasedRoute;