// This file contains the NotFound component. The component is exported and used in the App component.
// The NotFound component displays a 404 error message and provides links to the home page and login page.
// The component is rendered when the user navigates to a non-existent route.

import React from "react";
import { Link } from "react-router-dom";
import "./NotFound.css"; // Import the CSS file for styling

const NotFound = () => {
  return (
    <div className="not-found-container">
      <img
        className="not-found-image"
        src="/images/404.webp"
        alt="404 image of collapsed star."
      />
      {/* <h1 className="not-found-title">404 - Page Not Found!</h1> */}
      <p className="not-found-message">
        These are not the pages you are looking for.
      </p>
      <div className="not-found-buttons">
        <Link to="/" className="not-found-button">
          Go to Home
        </Link>
        <Link to="/login" className="not-found-button">
          Login
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
