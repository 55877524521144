import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomEditor from "../components/CustomEditor";
import api from "../services/api";
import "./Course.css";

const LessonForm = () => {
  const { t } = useTranslation();
  const { courseId } = useParams(); // Get course ID from the route
  const [lesson, setLesson] = useState({
    title: "",
    content: "",
    course_id: courseId, // Associate lesson with the course
    language: "en",
    position: 1, // Default position
    image: "",
    image_above: "",
    image_below: "",
  });
  const [error, setError] = useState(null);
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Create a new lesson
      await api.post(`/courses/${courseId}/lessons`, lesson);
      alert(t("lesson_created_success")); // Show success message
      history.push(`/courses/${courseId}`); // Redirect to the course detail page
    } catch (error) {
      console.error("Error creating lesson:", error);
      setError(t("save_error")); // Set error message for UI
    }
  };

  return (
    <div className="create-course-container">
      <h2>{t("create_lesson_header")}</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">{t("lesson_title")}</label>
          <input
            type="text"
            id="title"
            className="form-control"
            value={lesson.title}
            onChange={(e) => setLesson({ ...lesson, title: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="content">{t("lesson_content")}</label>
          <CustomEditor
            value={lesson.content}
            onTextChange={(e) => setLesson({ ...lesson, content: e.htmlValue })}
            className="custom-editor"
          />
        </div>
        <div className="form-group">
          <label htmlFor="language">{t("lesson_language")}</label>
          <select
            id="language"
            className="form-control"
            value={lesson.language}
            onChange={(e) => setLesson({ ...lesson, language: e.target.value })}
          >
            <option value="en">{t("language_english")}</option>
            <option value="fi">{t("language_finnish")}</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="position">{t("lesson_position")}</label>
          <input
            type="number"
            id="position"
            className="form-control"
            value={lesson.position}
            onChange={(e) => setLesson({ ...lesson, position: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="image">{t("lesson_image")}</label>
          <input
            type="text"
            id="image"
            className="form-control"
            value={lesson.image}
            onChange={(e) => setLesson({ ...lesson, image: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="image_above">{t("lesson_image_above")}</label>
          <input
            type="text"
            id="image_above"
            className="form-control"
            value={lesson.image_above}
            onChange={(e) =>
              setLesson({ ...lesson, image_above: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          <label htmlFor="image_below">{t("lesson_image_below")}</label>
          <input
            type="text"
            id="image_below"
            className="form-control"
            value={lesson.image_below}
            onChange={(e) =>
              setLesson({ ...lesson, image_below: e.target.value })
            }
          />
        </div>
        <button type="submit" className="btn-submit">
          {t("create_lesson_button")}
        </button>
      </form>
    </div>
  );
};

export default LessonForm;
