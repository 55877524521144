import React from "react";
import { format, isValid } from "date-fns";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";

const JournalEntries = ({ entries, handleDelete, history }) => {
  const { t } = useTranslation();

  return (
    <div className="journal-entries">
      {entries.length > 0 ? (
        entries.map((entry) => {
          const modifiedDate = entry.modified_at
            ? new Date(entry.modified_at)
            : null;
          const createdDate = entry.created_at
            ? new Date(entry.created_at)
            : null;

          const displayDate = isValid(modifiedDate)
            ? modifiedDate
            : isValid(createdDate)
            ? createdDate
            : null;

          return (
            <div key={entry.id} className="journal-card">
              <h3 className="journal-title">{entry.title}</h3>

              <div className="journal-metadata">
                <small className="journal-category">
                  <i className="fas fa-tag"></i> {entry.category}
                </small>
                <small className="journal-date">
                  <i className="fas fa-calendar-alt"></i>{" "}
                  {displayDate
                    ? format(displayDate, "dd/MM/yyyy")
                    : t("unknown_date")}
                </small>
              </div>

              <div className="journal-tags">
                {entry.tags && entry.tags.length > 0 ? (
                  entry.tags.map((tag, index) => (
                    <span key={index} className="journal-tag">
                      <i className="fas fa-hashtag"></i> {tag}
                    </span>
                  ))
                ) : (
                  <small className="journal-no-tags">{t("no_tags")}</small>
                )}
              </div>

              <div className="journal-content">
                {/* {entry.content} */}
                {/* trying to show html format */}
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(entry.content) }} />
              </div>

              <div className="journal-actions">
                <button
                  className="btn-edit"
                  onClick={() => history.push(`/journal/edit/${entry.id}`)}
                >
                  <i className="fas fa-edit"></i> {t("edit")}
                </button>
                <button
                  className="btn-delete"
                  onClick={() => handleDelete(entry.id)}
                >
                  <i className="fas fa-trash-alt"></i> {t("delete")}
                </button>
              </div>
            </div>
          );
        })
      ) : (
        <p>{t("no_entries_available")}</p>
      )}
    </div>
  );
};

export default JournalEntries;
