import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { register } from "../services/api";
import "./Login.css";
import "./Message.css";
import { useTranslation } from "react-i18next";
import { set } from "date-fns";

const Register = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [visibleMessage, setVisibleMessage] = useState(""); // Controls visible success message
  const [visibleError, setVisibleError] = useState(""); // Controls visible error message
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false); // Modal state
  const [termsContent, setTermsContent] = useState("");
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setMessage("");
    setVisibleError("");
    setVisibleMessage("");

    if (email !== confirmEmail) {
      setErrorMessage(t("emails_missmatch"));
      setVisibleError(t("emails_missmatch"));
      return;
    }
    if (password !== confirmPassword) {
      setErrorMessage(t("passwords_missmatch"));
      setVisibleError(t("passwords_missmatch"));
      return;
    }
    if (!acceptTerms) {
      setErrorMessage(t("accept_terms_required"));
      setVisibleError(t("accept_terms_required"));
      return;
    }
    try {
      const response = await register(username, password, email);
      if (response.error) {
        setErrorMessage(response.error);
        setVisibleError(response.error);
      } else if (response.message === "An error occurred during registration") {
        setErrorMessage(t("error_registering"));
        setVisibleError(t("error_registering"));
      } else {
        setMessage(response.message);
        setVisibleMessage(response.message);
        if (
          response.message === "User registered successfully" ||
          response.message === "Rekisteröinti onnistui"
        ) {
          setMessage(
            `${response.message}. ${t(
              "You_will_be_redirected_to_the_login_page_shortly"
            )}`
          );

          setVisibleMessage(
            `${response.message}. ${t(
              "You_will_be_redirected_to_the_login_page_shortly"
            )}`
          );

          setTimeout(() => {
            history.push("/login");
          }, 5000);
        }
      }
    } catch (error) {
      setErrorMessage(t("error_registering"));
      setVisibleError(t("error_registering"));
      console.error("Registration error:", error);
    }
  };

  const toggleTermsModal = () => {
    setShowTermsModal(!showTermsModal);
  };

  useEffect(() => {
    if (visibleError) {
      const timer = setTimeout(() => {
        setVisibleError(""); // Clear error message after fade-out
      }, 5000); // 3 seconds fade-out
      return () => clearTimeout(timer); // Cleanup timer
    }
  }, [visibleError]);

  useEffect(() => {
    if (visibleMessage) {
      const timer = setTimeout(() => {
        setVisibleMessage(""); // Clear success message after fade-out
      }, 5000); // 2 seconds fade-out
      return () => clearTimeout(timer); // Cleanup timer
    }
  }, [visibleMessage]);

  useEffect(() => {
    if (showTermsModal) {
      // Determine the user's language
      const userLanguage = i18n.language || "en"; // default to English if language not set
      const termsFile = userLanguage.startsWith("fi")
        ? "terms_fi.html"
        : "terms_en.html";

      // Fetch the terms content
      fetch(`/terms/${termsFile}`)
        .then((response) => response.text())
        .then((data) => {
          setTermsContent(data);
        })
        .catch((error) => {
          console.error("Error loading terms:", error);
          setTermsContent(t("error_loading_terms"));
        });
    }
  }, [showTermsModal, i18n.language]);

  return (
    <div className="login-container">
      <h2>{t("register")}</h2>
      <form onSubmit={handleSubmit} className="login-form">
        <div className="form-group">
          <label>{t("username")}</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            tabIndex="1"
          />
        </div>
        <div className="form-group">
          <label>{t("password")}</label>
          <div className="password-container">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              tabIndex="2"
            />
            <button
              type="button"
              className="toggle-password-btn"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? t("hide") : t("show")}
            </button>
          </div>
        </div>
        <div className="form-group">
          <label>{t("confirm_password")}</label>
          <div className="password-container">
            <input
              type={showPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              tabIndex="3"
            />
            <button
              type="button"
              className="toggle-password-btn"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? t("hide") : t("show")}
            </button>
          </div>
        </div>
        <div className="form-group">
          <label>{t("email")}</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            tabIndex="4"
          />
        </div>
        <div className="form-group">
          <label>{t("confirm_email")}</label>
          <input
            type="email"
            value={confirmEmail}
            onChange={(e) => setConfirmEmail(e.target.value)}
            required
            tabIndex="5"
          />
        </div>

        <div className="form-group2">
          <label className="custom-checkbox-label">
            {t("accept_terms")}{" "}
            <span
              className="terms-link"
              onClick={toggleTermsModal} // Corrected to use React event handler
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              {t("read_terms")}
            </span>
            <input
              type="checkbox"
              className="custom-checkbox-box"
              checked={acceptTerms}
              onChange={(e) => setAcceptTerms(e.target.checked)}
            />
            {/* <span className="custom-checkbox-box"></span> */}
          </label>
        </div>

        {visibleError && (
          <p className={`message error ${!visibleError ? "fade-out" : ""}`}>
            {errorMessage}
          </p>
        )}
        {visibleMessage && (
          <p className={`message success ${!visibleMessage ? "fade-out" : ""}`}>
            {message}
          </p>
        )}

        <button type="submit">{t("register")}</button>
      </form>

      {/* Terms Modal */}
      {showTermsModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>{t("terms_of_use")}</h2>

            <div
              className="terms-content"
              dangerouslySetInnerHTML={{ __html: termsContent }}
            />
            <button onClick={toggleTermsModal}>{t("close")}</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Register;
