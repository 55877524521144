import React, { useEffect, useState } from 'react';
import { getProfile } from "../services/api";

const RoleBasedComponent = ({ requiredRole, children, loggedIn }) => {
  const [hasRole, setHasRole] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getProfile();
        const { roles } = response;
        //console.log('Roles:', roles);
        if (roles.includes(requiredRole)) {
          setHasRole(true);
        } else {
          setHasRole(false);
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
        setHasRole(false);
      } finally {
        setLoading(false);
      }
    };

    if (localStorage.getItem("isLoggedIn") === "true") {
      fetchProfile();
    } else {
      setLoading(false);
    }
  }, [requiredRole, loggedIn]);

  if (loading) {
    return null; // Voit myös näyttää latausanimaation tässä
  }

  if (!hasRole) {
    return null;
  }

  return <>{children}</>;
};

export default RoleBasedComponent;