import React from 'react';
import './Octagon.css';  // Import the CSS for the octagon layout

const Octagon = ({ values }) => {
    // 'values' is an array of 8 boolean values (true or false)
    return (
        <div className="octagon-container">
            {values.map((value, index) => (
                <div 
                    key={index}
                    className="circle"
                    style={{ backgroundColor: value ? 'black' : 'white' }}
                />
            ))}
        </div>
    );
};

export default Octagon;
